import { AppDetails } from '@features/app-management/components/AppDetails';
import { UserFeedback } from '@features/feedback/components/UserFeedback';
import { useSuspenseApp } from '@features/app-management/queries';
import { useAppFeedback } from '@features/feedback/queries';
import styles from './AppPage.module.scss';

export interface AppPageProps {
  appId: string;
}

export function AppPage({ appId }: AppPageProps) {
  const { data: app } = useSuspenseApp(appId);
  const { data: feedback, isLoading: isFeedbackLoading } = useAppFeedback(appId);

  if (!app) {
    return null;
  }

  return (
    <main>
      <AppDetails app={app} />

      <UserFeedback feedback={feedback} className={styles.feedback} isLoading={isFeedbackLoading} />
    </main>
  );
}
