import { Icon, Rating, Typography } from '@prepaire/ui-kit';
import dayjs from 'dayjs';
import { S3FileModel } from '@shared/entities/s3-file/types';
import styles from './AppReviewRequestSummary.module.scss';
import { ApproveRequest } from './ApproveRequest';
import { DeclineRequest } from './DeclineRequest';

export interface AppRequestSummaryProps {
  reqId: string | number;
  authorName: string;
  text: string;
  rating: number;
  maxRating?: number;
  authorAvatar?: S3FileModel;
  date?: string;
}

export const AppReviewRequestSummary = ({
  authorName,
  text,
  authorAvatar,
  date,
  reqId,
  rating,
  maxRating = 5,
}: AppRequestSummaryProps) => {
  return (
    <article className={styles.summary}>
      <div className={styles.body}>
        {authorAvatar?.url ? (
          <img className={styles.logo} width={55} height={55} src={authorAvatar.url} alt={authorName} />
        ) : (
          <Icon.Profile className={styles.logo} width={55} height={55} />
        )}
        <div className={styles.info}>
          <Typography variant="h5" component="p">
            {authorName}
          </Typography>
          <Typography variant="caption">{dayjs(date).format('MMMM DD, YYYY')}</Typography>
          <Rating className={styles.rating} maxRating={maxRating} rating={rating} />
        </div>
        <Typography className={styles.text} variant="body1">
          {text}
        </Typography>
      </div>
      <div className={styles.controls}>
        <ApproveRequest requestId={reqId} />
        <DeclineRequest requestId={reqId} />
      </div>
    </article>
  );
};
