import { z } from 'zod';
import { CreatorModel, CreatorResponseSchema } from '../creator/types';

export interface AppReviewModel {
  id: string | number;
  creator: CreatorModel;
  text: string;
  rating: number;
  createdAt: string;
  response: DevReviewResponseModel;
}

export type DevReviewResponseModel = Omit<AppReviewModel, 'rating' | 'response'>;

export const AppReviewResponseSchema = z.object({
  id: z.union([z.string(), z.number()]),
  creator: CreatorResponseSchema,
  text: z.string(),
  rating: z.number(),
  createdAt: z.string(),
  response: z.object({
    id: z.union([z.string(), z.number()]),
    creator: CreatorResponseSchema,
    text: z.string(),
    createdAt: z.string(),
  }),
});
