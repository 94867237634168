import { Typography } from '@prepaire/ui-kit';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AppStatusEnum } from '@shared/entities/app/types';
import styles from './AppStatus.module.scss';

export interface AppStatusProps {
  status?: AppStatusEnum;
  className?: string;
}

export function AppStatus({ status, className }: AppStatusProps) {
  const { t } = useTranslation('appManagement');

  if (!status) {
    return null;
  }

  return (
    <Typography
      variant="h5"
      component="p"
      className={clsx(className, styles.status, {
        [styles.statusActive]: status === AppStatusEnum.Active,
        [styles.statusError]: status === AppStatusEnum.NeedsAttention,
      })}
    >
      {status === AppStatusEnum.Active && t('statusActive')}
      {status === AppStatusEnum.NeedsAttention && t('statusNeedsAttention')}
      {status === AppStatusEnum.Pending && t('statusPending')}
      {status === AppStatusEnum.Draft && t('statusDraft')}
    </Typography>
  );
}
