import { useSuspenseAppReviewRequests } from '@features/app-feedback-moderation/queries';
import { IncomingAppReviews } from '@features/app-feedback-moderation/components/IncomingAppReviews';
import { useTranslation } from 'react-i18next';
import styles from '@pages/app-requests/AppRequestsPage/AppRequestList.module.scss';
import { Typography } from '@prepaire/ui-kit';

export const AppReviewsModerationPage = () => {
  const { t } = useTranslation('appReviewRequestManagement');
  const { data } = useSuspenseAppReviewRequests();

  if (!data) {
    return null;
  }

  return (
    <main>
      <Typography className={styles.title} variant="h1">
        {t('requestsTitle')}
      </Typography>
      <IncomingAppReviews reviews={data} />
    </main>
  );
};
