import { useSuspenseApp } from '@features/app-management/queries';
import { AppLogoUpdateForm } from '@features/app-management/components/AppLogoUpdateForm';

interface EditAppLogoPageProps {
  appId: string;
}

export function EditAppLogoPage({ appId }: EditAppLogoPageProps) {
  const { data: app } = useSuspenseApp(appId);

  if (!app) {
    return null;
  }

  return <AppLogoUpdateForm appId={app.id} appName={app.name} />;
}
