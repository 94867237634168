import { Button, FormField, Input, Typography, useFailDialog, useSuccessDialog } from '@prepaire/ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from '@tanstack/react-router';
import { useAccount } from '@shared/features/auth/queries';
import { useReleaseApp } from '../../queries';
import { AppReleaseFormData, appReleaseSchema, TEXT_FIELD_MAX } from './formSchema';

import styles from './AppReleaseForm.module.scss';

export interface AppReleaseFormProps {
  appId: string;
  appName: string;
}

const redirectTimeout = 3000;

export function AppReleaseForm({ appId, appName }: AppReleaseFormProps) {
  const { t } = useTranslation('appUpdate');
  const { t: tC } = useTranslation('common');
  const user = useAccount();

  const { mutateAsync: releaseApp, isPending } = useReleaseApp();

  const navigate = useNavigate();

  const [successDialog, toggleSuccessDialog] = useSuccessDialog({
    okText: tC('ok'),
    content: t('successText', { count: redirectTimeout / 1000 }),
  });
  const [failDialog, toggleFailDialog] = useFailDialog({
    okText: tC('ok'),
    content: t('errorText'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AppReleaseFormData>({
    resolver: zodResolver(appReleaseSchema),
  });

  const onSubmit = async (formData: AppReleaseFormData) => {
    if (!user) {
      return;
    }

    try {
      await releaseApp({
        id: appId,
        creator_id: user.id,
        release_notes: formData.releaseNotes,
        changelog: formData.changelogs,
        version: formData.version,
        file: formData.fileOrLink,
      });
    } catch (error) {
      toggleFailDialog();
      return;
    }
    toggleSuccessDialog();

    setTimeout(() => {
      navigate({ to: '/apps/$appId', params: { appId } });
    }, redirectTimeout);
  };

  return (
    <main className={clsx(styles.appRelease)}>
      {/* <ProgressBar isShown={progress !== 0} progress={progress} fullScreen> */}
      {/*  <Typography variant="h2">{t('progressMsg')}</Typography> */}
      {/* </ProgressBar> */}
      {successDialog}
      {failDialog}
      <Typography variant="h1" className={styles.title}>
        {t('releaseTitle', { name: appName })}
      </Typography>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <FormField name="name" label={<Typography variant="h4">{t('versionLabel')}</Typography>} errors={errors.version}>
          <Controller name="version" control={control} render={({ field }) => <Input {...field} maxLength={TEXT_FIELD_MAX} />} />
        </FormField>

        <FormField name="info" label={<Typography variant="h4">{t('changelogsLabel')}</Typography>} errors={errors.changelogs}>
          <Controller
            name="changelogs"
            control={control}
            render={({ field }) => <Input.Textarea {...field} maxLength={TEXT_FIELD_MAX} />}
          />
        </FormField>

        <FormField
          name="releaseNotes"
          label={<Typography variant="h4">{t('releaseNotesLabel')}</Typography>}
          errors={errors.releaseNotes}
        >
          <Controller
            name="releaseNotes"
            control={control}
            render={({ field }) => <Input.Textarea {...field} maxLength={TEXT_FIELD_MAX} />}
          />
        </FormField>

        <FormField name="fileOrLink" label={<Typography variant="h4">{t('repoLabel')}</Typography>} hint={t('repoHint')}>
          <Controller
            name="fileOrLink"
            control={control}
            render={({ field }) => <Input.FileOrLink onChange={field.onChange} description={t('repoPh')} />}
          />
        </FormField>
        <div className={styles.formControls}>
          <Button type="submit" variant="primary" disabled={isPending}>
            {t('submitBtn')}
          </Button>
        </div>
      </form>
    </main>
  );
}
