import { z } from 'zod';
import { AppRequestResponseSchema, AppRequestStatusEnum } from '@entities/app-request/types';

/* --------------------------- APP REQUEST --------------------------- */

export type AppRequestResponse = z.infer<typeof AppRequestResponseSchema>;

export const AppRequestsResponseSchema = z.array(AppRequestResponseSchema);
export type AppRequestsResponse = z.infer<typeof AppRequestsResponseSchema>;

/* --------------------------- APP REQUEST STATUS --------------------------- */

export interface UpdateAppRequestStatusRequest {
  id: string | number;
  status: AppRequestStatusEnum;
  feedback_message?: string;
}

export const UpdateAppRequestStatusSchema = AppRequestResponseSchema;
export type UpdateAppRequestStatusResponse = z.infer<typeof UpdateAppRequestStatusSchema>;
