import { useAppScreenshotFiles, useSuspenseApp } from '@features/app-management/queries';
import { AppScreenshotUpdateForm } from '@features/app-management/components/AppScreenshotUpdateForm';

interface EditAppScreenshotsPageProps {
  appId: string;
}

export function EditAppScreenshotsPage({ appId }: EditAppScreenshotsPageProps) {
  const { data: app } = useSuspenseApp(appId);

  const { data: appScreenshots } = useAppScreenshotFiles(appId, app?.images);

  if (!app || !appScreenshots) {
    return null;
  }

  return <AppScreenshotUpdateForm appId={app.id} appName={app.name} screenshots={appScreenshots} />;
}
