import { QueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

const NO_REDIRECT_STATUS = new Set<number | undefined>([401, 403, 404]);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      retry(_, error) {
        return isAxiosError(error) && !NO_REDIRECT_STATUS.has(error.response?.status);
      },
    },
  },
});
