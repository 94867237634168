import { UserComment } from '@features/feedback/components/UserComment';
import clsx from 'clsx';
import { AppReviewModel } from '@shared/entities/feedback/types';
import { Loader } from '@prepaire/ui-kit';
import { getName } from '@shared/utils/getName';
import styles from './UserFeedback.module.scss';

export interface UserFeedbackProps {
  isLoading: boolean;
  feedback?: AppReviewModel[];
  className?: string;
}

export const UserFeedback = ({ feedback, className, isLoading }: UserFeedbackProps) => {
  return (
    <section className={clsx(styles.userFeedback, className)}>
      {isLoading && !feedback && <Loader />}
      {feedback?.map((f) => (
        <UserComment key={f.id} text={f.text} userName={getName(f.creator)} userAvatar={f.creator.avatar} rating={f.rating} />
      ))}
    </section>
  );
};
