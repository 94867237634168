import { createFileRoute } from '@tanstack/react-router';
import { EditAppLogoPage } from '@pages/app-management/EditAppLogoPage';

export const Route = createFileRoute('/_auth/_main/apps/$appId/logo')({
  component: function EditComponent() {
    const { appId } = Route.useParams();

    return <EditAppLogoPage appId={appId} />;
  },
});
