import { createFileRoute, Navigate, Outlet } from '@tanstack/react-router';
import { useCan } from '@shared/services/ability';
import { Action, Subject } from '@services/ability/parameters';

export const Route = createFileRoute('/_auth/_main/admin/_admin')({
  component: function AdminAuth() {
    const canView = useCan(Action.Read, Subject.AdminRoutes);
    if (!canView) {
      return <Navigate to="/" />;
    }
    return <Outlet />;
  },
});
