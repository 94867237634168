import { AppRequests } from '@features/app-request-management/components/AppRequests';
import { Typography } from '@prepaire/ui-kit';
import { useTranslation } from 'react-i18next';
import { useSuspenseAppRequests } from '@features/app-request-management/queries';
import styles from './AppRequestList.module.scss';

export const AppRequestListPage = () => {
  const { t } = useTranslation('appRequestManagement');

  const { data } = useSuspenseAppRequests();

  if (!data) {
    return null;
  }

  return (
    <main>
      <Typography className={styles.title} variant="h1">
        {t('requestsTitle')}
      </Typography>
      <AppRequests requests={data} />
    </main>
  );
};
