import { createFileRoute } from '@tanstack/react-router';
import { ensureApp } from '@features/app-management/queries';
import { AppPage } from '@pages/app-management/AppPage';
import { ensureAppFeedback } from '@features/feedback/queries';

export const Route = createFileRoute('/_auth/_main/apps/$appId/')({
  loader: ({ params }) => {
    // do not await! it's important to start loading feedback here and only await the app itself
    ensureAppFeedback(params.appId);
    return ensureApp(params.appId);
  },
  pendingComponent: () => <div>PENDING</div>,
  errorComponent: () => <div>ERROR</div>,
  component: function AppComponent() {
    const { appId } = Route.useParams();

    return <AppPage appId={appId} />;
  },
});
