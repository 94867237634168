const appRequestManagement = {
  statusPending: 'Pending',
  statusReturned: 'Returned for Revision',
  statusApproved: 'Approved',
  statusRejected: 'Rejected',

  requestsTitle: 'New App Requests',

  requestTitle: 'Request for app "{{name}}"',

  approveBtn: 'Approve',
  rejectBtn: 'Decline',

  approveText: 'Approve app "{{name}}" to be added to platform?',
  approveSuccessText: 'App approved successfully. <Link>View app requests</Link>',
  approveErrorText: 'Could not approve the app request due to error',

  rejectPhText: 'Rejection reason',
  rejectSuccessText: 'App was rejected',
  rejectErrorText: 'Could not decline the app request due to error',

  sourceLink: 'Browse source code',

  screenshots: 'Screenshots',
  logo: 'Logo',
  description: 'Application information',
  technicalDetails: 'Technical description of functionality',
  author: 'Author',
} as const;

export default appRequestManagement;
