import { createFileRoute } from '@tanstack/react-router';
import { ensureAppRequest } from '@features/app-request-management/queries';
import { AppRequestPage } from '@pages/app-requests/AppRequestPage';

export const Route = createFileRoute('/_auth/_main/admin/_admin/app-requests/$reqId/')({
  loader: ({ params }) => ensureAppRequest(params.reqId),
  component: function AppRequestsComponent() {
    const { reqId } = Route.useParams();

    return <AppRequestPage appRequestId={reqId} />;
  },
  pendingComponent: () => <div>PENDING</div>,
  errorComponent: () => <div>ERROR</div>,
});
