import { useTranslation } from 'react-i18next';
import { useNavigate } from '@tanstack/react-router';
import { Button, FormField, Input, Typography, useFailDialog, useSuccessDialog } from '@prepaire/ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { DevAgreementFormData, devAgreementSchema } from '@features/developer-agreement/UploadDeveloperAgreement/formSchema';
import { useUploadAgreement } from '@features/developer-agreement/queries';
import styles from './UploadDeveloperAgreement.module.scss';

const redirectTimeout = 3000;
export const UploadDeveloperAgreement = () => {
  const { t } = useTranslation('devAgreement');
  const { t: tC } = useTranslation('common');

  const { mutateAsync: uploadAgreement, isPending } = useUploadAgreement();

  const navigate = useNavigate();

  const [successDialog, toggleSuccessDialog] = useSuccessDialog({
    okText: tC('ok'),
    content: t('successText', { count: redirectTimeout / 1000 }),
  });
  const [failDialog, toggleFailDialog] = useFailDialog({ okText: tC('ok'), content: t('errorText') });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<DevAgreementFormData>({
    resolver: zodResolver(devAgreementSchema),
  });

  const onSubmit = async (formData: DevAgreementFormData) => {
    try {
      await uploadAgreement({
        file: formData.file,
        agreed: formData.agreed,
      });
    } catch (error) {
      toggleFailDialog();
      return;
    }
    toggleSuccessDialog();

    setTimeout(() => {
      navigate({ to: '/apps' });
    }, redirectTimeout);
  };

  return (
    <main className={clsx(styles.devAgreement)}>
      {successDialog}
      {failDialog}
      <Typography variant="h3" className={styles.title}>
        {t('title')}
      </Typography>

      <Typography variant="body1" className={styles.subTitle} component="p">
        {t('subTitle')}
      </Typography>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <FormField name="file" errors={errors.file}>
          <Controller
            name="file"
            control={control}
            render={({ field }) => <Input.FileOrLink onChange={field.onChange} description={t('fileLabel')} onlyFile />}
          />
        </FormField>

        <div className={styles.formBottom}>
          <Controller
            name="agreed"
            control={control}
            render={({ field }) => (
              <div className={styles.agreedCheckbox}>
                <Input.Checkbox {...field} />
                <Typography variant="body1">
                  <label htmlFor={field.name}>{t('checkLabel')}</label>
                </Typography>
              </div>
            )}
          />

          <Button type="submit" variant="primary" disabled={isPending} loader={isPending}>
            {t('submitBtn')}
          </Button>
        </div>
      </form>
    </main>
  );
};
