import { useIsMutating, useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { queryClient } from '@shared/services/api/queryClient';
import { UpdateAppRequestStatusRequest } from '@features/app-request-management/types';
import { fetchAppRequest, fetchAppRequests, updateAppRequestStatus } from './api';

const appRequestQueryKey = 'app-request';

export const useSuspenseAppRequests = () =>
  useSuspenseQuery({
    queryFn: fetchAppRequests,
    queryKey: [appRequestQueryKey],
  });

export const ensureAppRequests = () =>
  queryClient.ensureQueryData({
    queryFn: fetchAppRequests,
    queryKey: [appRequestQueryKey],
  });

export const useSuspenseAppRequest = (id: string) =>
  useSuspenseQuery({
    queryFn: () => fetchAppRequest(id),
    queryKey: [appRequestQueryKey, id],
  });

export const ensureAppRequest = (id: string) =>
  queryClient.ensureQueryData({
    queryFn: () => fetchAppRequest(id),
    queryKey: [appRequestQueryKey, id],
  });

const appRequestStatusMutationKey = 'app-request-status';
export const useUpdateAppRequestStatus = () =>
  useMutation({
    mutationKey: [appRequestStatusMutationKey],
    mutationFn: (data: UpdateAppRequestStatusRequest) => updateAppRequestStatus(data),
    async onSuccess(responseAppRequest, { id }) {
      queryClient.setQueryData([appRequestQueryKey, id.toString()], responseAppRequest);
      await queryClient.invalidateQueries({ queryKey: [appRequestQueryKey] });
    },
  });

export const useIsMutatingAppRequestStatus = () => {
  const mutationNumber = useIsMutating({
    mutationKey: [appRequestStatusMutationKey],
  });

  return mutationNumber !== 0;
};
