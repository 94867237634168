import { AuthToken } from './types';
import { Account } from '../../entities/user/types';

export const getAuthToken = () => {
  const tokenStr = localStorage.getItem('authToken');

  if (!tokenStr) return undefined;

  try {
    return JSON.parse(tokenStr) as AuthToken;
  } catch (e) {
    return undefined;
  }
};

export const setAuthToken = (token: AuthToken) => {
  localStorage.setItem('authToken', JSON.stringify(token));
};

export const getRefreshToken = () => {
  const tokenStr = localStorage.getItem('refreshToken');

  if (!tokenStr) return undefined;

  try {
    return JSON.parse(tokenStr) as AuthToken;
  } catch (e) {
    return undefined;
  }
};

export const setRefreshToken = (token: AuthToken) => {
  localStorage.setItem('refreshToken', JSON.stringify(token));
};

export const getUserAccount = () => {
  const tokenStr = localStorage.getItem('userAccount');

  if (!tokenStr) return undefined;

  try {
    return JSON.parse(tokenStr) as Account;
  } catch (e) {
    return undefined;
  }
};

export const setUserAccount = (account: Account) => {
  localStorage.setItem('userAccount', JSON.stringify(account));
};

export const clearStorage = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userAccount');
};
