import { Trans, useTranslation } from 'react-i18next';
import { useUpdateAppRequestStatus } from '@features/app-request-management/queries';
import { Link } from '@tanstack/react-router';
import { Button, useConfirmDialog } from '@prepaire/ui-kit';
import { AppRequestStatusEnum } from '@entities/app-request/types';

export interface ApproveRequestProps {
  requestId: string | number;
  appName: string;
  disabled?: boolean;
}

export const ApproveRequest = ({ requestId, appName, disabled }: ApproveRequestProps) => {
  const { t } = useTranslation('appRequestManagement');
  const { t: tC } = useTranslation('common');

  const {
    mutateAsync: approveAppRequest,
    status: approveMutationStatus,
    reset: resetApproveMutation,
    isPending,
  } = useUpdateAppRequestStatus();

  const [dialog, toggleApproveDialog] = useConfirmDialog({
    okText: t('approveBtn'),
    cancelText: tC('cancel'),
    closeText: tC('ok'),
    content: t('approveText', { name: appName }),
    errorContent: t('approveErrorText'),
    successContent: <Trans t={t} i18nKey="approveSuccessText" components={{ Link: <Link to="/admin/app-requests" /> }} />,
    status: approveMutationStatus,
    async onOk() {
      await approveAppRequest({ id: requestId, status: AppRequestStatusEnum.Approved });
    },
    afterClose() {
      if (!isPending) {
        resetApproveMutation();
      }
    },
  });

  return (
    <>
      {dialog}
      <Button variant="primary" onClick={() => toggleApproveDialog(true)} loader={isPending} disabled={isPending || disabled}>
        {t('approveBtn')}
      </Button>
    </>
  );
};
