import { useIsMutating, useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { queryClient } from '@shared/services/api/queryClient';
import { UpdateAppReviewRequestStatusRequest } from '@features/app-feedback-moderation/types';
import { fetchAppReviewRequests, updateAppReviewRequestStatus } from './api';

const appReviewRequestQueryKey = 'app-review-request';

export const useSuspenseAppReviewRequests = () =>
  useSuspenseQuery({
    queryFn: fetchAppReviewRequests,
    queryKey: [appReviewRequestQueryKey],
  });

export const ensureAppReviewRequests = () =>
  queryClient.ensureQueryData({
    queryFn: fetchAppReviewRequests,
    queryKey: [appReviewRequestQueryKey],
  });

const appReviewRequestStatusMutationKey = 'app-review-request-status';
export const useUpdateReviewRequestStatus = () =>
  useMutation({
    mutationKey: [appReviewRequestStatusMutationKey],
    mutationFn: (data: UpdateAppReviewRequestStatusRequest) => updateAppReviewRequestStatus(data),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [appReviewRequestQueryKey] });
    },
  });

export const useIsMutatingAppReviewRequestStatus = () => {
  const mutationNumber = useIsMutating({
    mutationKey: [appReviewRequestStatusMutationKey],
  });

  return mutationNumber !== 0;
};
