import { AppRequestSummary } from '@features/app-request-management/components/AppRequestSummary';
import { getName } from '@shared/utils/getName';
import clsx from 'clsx';
import { AppRequestModel } from '@entities/app-request/types';
import styles from './AppRequests.module.scss';

export interface AppRequestsProps {
  requests: AppRequestModel[];
  className?: string;
}

export const AppRequests = ({ className, requests }: AppRequestsProps) => {
  return (
    <section className={clsx(className, styles.requests)}>
      {requests.map((item) => (
        <AppRequestSummary
          key={item.id}
          reqId={item.id}
          appName={item.app.name}
          devName={getName(item.app.creator)}
          description={item.app.description}
          date={item.createdAt}
          logo={item.app.logo}
        />
      ))}
    </section>
  );
};
