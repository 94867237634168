import urlJoin from 'url-join';

const { VITE_APP_MANAGEMENT_SERVICE_URL, VITE_ADMIN_SERVICE_URL, VITE_PREPAIRE_PLATFORM_URL } = import.meta.env;

export const PLATFORM_URL = VITE_PREPAIRE_PLATFORM_URL || window.location.origin;

const localServiceUrl = urlJoin(window.location.origin, 'api');

export const APP_MANAGEMENT_SERVICE_URL = VITE_APP_MANAGEMENT_SERVICE_URL || localServiceUrl;
export const ADMIN_SERVICE_URL = VITE_ADMIN_SERVICE_URL || localServiceUrl;
