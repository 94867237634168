export enum UserRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export interface Account {
  id: string;
  email: string;
  isBlocked: boolean;
  isPassChange: boolean;
  isVerified: boolean;
  role: UserRole;
}
