import Axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import urlJoin from 'url-join';
import { getAuthToken, getRefreshToken, setAuthToken, setRefreshToken, setUserAccount } from '../auth/tokenStorage';
import { LoginResponse } from '../auth/types';
import { endpoints } from '../auth/endpoints';
import { queryClient } from './queryClient';
import { ACCOUNT_QUERY_KEY } from '../auth/queryKeys';
import { AUTH_GATEWAY_URL, SIGNIN_PAGE_URL, SIGNIN_SERVICE_URL } from '../service-urls';

interface AxiosConfigRetried extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

const axiosInstance = Axios.create();

interface RefreshTokenManager {
  refreshPromise: Promise<void> | undefined;

  refreshToken(refToken: string): Promise<void>;
}

const refreshTokenManager: RefreshTokenManager = {
  refreshPromise: undefined,
  refreshToken(refToken: string) {
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    this.refreshPromise = axiosInstance
      .post(endpoints.refresh.expand({}), null, {
        headers: {
          Authorization: `Bearer ${refToken}`,
        },
      })
      .then((response) => response.data)
      .then((data: LoginResponse) => {
        setRefreshToken(data.authTokens.refresh);
        setAuthToken(data.authTokens.auth);
        setUserAccount(data.account);

        queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
      })
      .then(() => {
        this.refreshPromise = undefined;
      });

    return this.refreshPromise;
  },
};

const redirectToLoginPage = () => {
  window.location.href = SIGNIN_PAGE_URL;
};

const on401 = async (error: AxiosError) => {
  const originalRequest = error.config as AxiosConfigRetried;
  // eslint-disable-next-line no-underscore-dangle
  if (!originalRequest._retry) {
    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true;

    const refreshTokenStr = getRefreshToken()?.token;

    if (!refreshTokenStr) {
      redirectToLoginPage();
      throw error;
    }

    try {
      await refreshTokenManager.refreshToken(refreshTokenStr);

      return await axiosInstance(originalRequest);
    } catch (err) {
      if (Axios.isAxiosError(err) && err.status === 401) {
        redirectToLoginPage();
      }

      throw error;
    }
  }

  throw error;
};

const responseErrorHandler = (error: AxiosError) => {
  const { response } = error;

  if (response?.status === 401) {
    return on401(error);
  }

  return Promise.reject(error);
};

const attachTokenToRequest = (config: InternalAxiosRequestConfig) => {
  if (config.headers.Authorization) {
    return config;
  }

  const token = getAuthToken()?.token;
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const sendRequestThroughGateway = (config: InternalAxiosRequestConfig) => {
  const requestUrl = config.url;

  if (!requestUrl) {
    return config;
  }

  if (requestUrl.startsWith(SIGNIN_SERVICE_URL)) {
    return config;
  }

  if (requestUrl.startsWith(AUTH_GATEWAY_URL)) {
    return config;
  }

  const pathName = new URL(requestUrl).pathname;

  const nextPathName = urlJoin(AUTH_GATEWAY_URL, pathName);

  // eslint-disable-next-line no-param-reassign
  config.url = nextPathName;

  return config;
};

axiosInstance.interceptors.response.use((response) => response, responseErrorHandler);

if (!import.meta.env.VITE_DEV_DISABLE_AUTH_GATEWAY) {
  axiosInstance.interceptors.request.use(sendRequestThroughGateway);
}
axiosInstance.interceptors.request.use(attachTokenToRequest);

export { axiosInstance };
