import { z } from 'zod';

export interface S3FileModel {
  id: string | number;
  name: string;
  s3Key: string;
  url: string;
  fileType: string;
}

export const S3FileSchema = z.object({
  id: z.union([z.string(), z.number()]),
  name: z.string(),
  s3_key: z.string(),
  url: z.string(),
  file_type: z.string(),
});

export type S3FileSchemaType = z.infer<typeof S3FileSchema>;
