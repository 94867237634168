import { parseTemplate } from 'url-template';

const PATH_PARAM_REGEX = /\{(\w+)\}/g;
const URL_PARAM_REGEX = /\{\?(\w+)\}/g;

export const parseEndpoint = (url: string) => {
  const template = parseTemplate(url);

  const mswTpl = url.replace(PATH_PARAM_REGEX, ':$1').replace(URL_PARAM_REGEX, '');

  return {
    expand: template.expand,
    mswTpl,
    raw: url,
  };
};
