import { createFileRoute } from '@tanstack/react-router';
import { MyAppsPage } from '@pages/app-management/MyAppsPage';
import { ensureApps } from '@features/app-management/queries';

export const Route = createFileRoute('/_auth/_main/apps/_apps-layout/')({
  component: MyAppsPage,
  loader: () => ensureApps(),
  pendingComponent: () => <div>PENDING</div>,
  errorComponent: () => <div>ERROR</div>,
});
