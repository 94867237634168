import { useContext } from 'react';
import { AbilityContext } from './AbilityContext';

export const useCan = (action: string, subject: string) => {
  const ability = useContext(AbilityContext);

  if (!ability) {
    return false;
  }

  return ability.can(action, subject);
};
