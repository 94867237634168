import { Button, FormField, Input, Typography, useFailDialog, useSuccessDialog } from '@prepaire/ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from '@tanstack/react-router';
import { useUpdateApp } from '../../queries';
import { AppUpdateFormData, appUpdateSchema, TEXT_FIELD_MAX } from './formSchema';

import styles from './AppUpdateForm.module.scss';

interface AppUpdateFormProps {
  appId: string | number;
  appName: string;
  appDescription: string;
  appTechnicalDetails: string;
}

const redirectTimeout = 3000;

export function AppUpdateForm({ appName, appDescription, appTechnicalDetails, appId }: AppUpdateFormProps) {
  const { t } = useTranslation('appUpdate');
  const { t: tC } = useTranslation('common');

  const { mutateAsync: updateApp, isPending } = useUpdateApp();

  const navigate = useNavigate();

  const [successDialog, toggleSuccessDialog] = useSuccessDialog({
    okText: tC('ok'),
    content: t('successText', { count: redirectTimeout / 1000 }),
  });
  const [failDialog, toggleFailDialog] = useFailDialog({ okText: tC('ok'), content: t('errorText') });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AppUpdateFormData>({
    resolver: zodResolver(appUpdateSchema),
    defaultValues: {
      name: appName ?? '',
      description: appDescription ?? '',
      technicalDetails: appTechnicalDetails ?? '',
    },
  });

  const onSubmit = async (formData: AppUpdateFormData) => {
    try {
      await updateApp({
        id: appId,
        name: formData.name,
        technical_details: formData.technicalDetails,
        description: formData.description,
      });
    } catch (error) {
      toggleFailDialog();
      return;
    }
    toggleSuccessDialog();

    setTimeout(() => {
      navigate({ to: '/apps/$appId', params: { appId: appId.toString() } });
    }, redirectTimeout);
  };

  return (
    <main className={clsx(styles.appUpdate)}>
      {successDialog}
      {failDialog}
      <Typography variant="h1" className={styles.title}>
        {t('title', { name: appName })}
      </Typography>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="name"
          label={<Typography variant="h4">{t('nameLabel')}</Typography>}
          hint={t('nameHint')}
          errors={errors.name}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => <Input {...field} placeholder={t('namePh')} maxLength={TEXT_FIELD_MAX} />}
          />
        </FormField>

        <FormField
          name="description"
          label={<Typography variant="h4">{t('descLabel')}</Typography>}
          hint={t('descHint')}
          errors={errors.description}
        >
          <Controller
            name="description"
            control={control}
            render={({ field }) => <Input.Textarea {...field} maxLength={TEXT_FIELD_MAX} placeholder={t('descPh')} />}
          />
        </FormField>

        <FormField
          name="technicalDetails"
          label={<Typography variant="h4">{t('techDetLabel')}</Typography>}
          hint={t('techDetHint')}
          errors={errors.technicalDetails}
        >
          <Controller
            name="technicalDetails"
            control={control}
            render={({ field }) => <Input.Textarea {...field} maxLength={TEXT_FIELD_MAX} placeholder={t('techDetPh')} />}
          />
        </FormField>

        <div className={styles.formBottom}>
          <Button type="submit" variant="primary" disabled={isPending} loader={isPending}>
            {t('submitBtn')}
          </Button>
        </div>
      </form>
    </main>
  );
}
