import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { queryClient } from '@shared/services/api/queryClient';
import {
  ReleaseAppRequest,
  UpdateAppImagesRequest,
  UpdateAppLogoRequest,
  UpdateAppRequest,
} from '@features/app-management/types';
import { S3FileModel } from '@shared/entities/s3-file/types';
import { fetchApp, fetchMyApps, fetchScreenshot, releaseApp, updateApp, updateAppImages, updateAppLogo, uploadApp } from './api';

export const useUploadApp = () =>
  useMutation({
    mutationFn: uploadApp,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['my-apps'] });
    },
  });

export const useSuspenseApps = () =>
  useSuspenseQuery({
    queryFn: fetchMyApps,
    queryKey: ['my-apps'],
  });

export const ensureApps = () =>
  queryClient.ensureQueryData({
    queryFn: fetchMyApps,
    queryKey: ['my-apps'],
  });

export const useSuspenseApp = (id: string) =>
  useSuspenseQuery({
    queryFn: () => fetchApp(id),
    queryKey: ['app', id],
  });

export const ensureApp = (id: string) =>
  queryClient.ensureQueryData({
    queryFn: () => fetchApp(id),
    queryKey: ['app', id],
  });

export const useReleaseApp = () =>
  useMutation({
    mutationFn: (data: ReleaseAppRequest) => releaseApp(data),
    onSuccess(_, { id }) {
      queryClient.invalidateQueries({ queryKey: ['my-apps'] });
      queryClient.invalidateQueries({ queryKey: ['app', id.toString()] });
    },
  });

export const useUpdateApp = () =>
  useMutation({
    mutationFn: (data: UpdateAppRequest) => updateApp(data),
    onSuccess(_, { id }) {
      queryClient.invalidateQueries({ queryKey: ['my-apps'] });
      queryClient.invalidateQueries({ queryKey: ['app', id.toString()] });
    },
  });

export const useUpdateAppImages = () =>
  useMutation({
    mutationFn: (data: UpdateAppImagesRequest) => updateAppImages(data),
    onSuccess(_, { id }) {
      queryClient.invalidateQueries({ queryKey: ['my-apps'] });
      queryClient.invalidateQueries({ queryKey: ['app', id.toString()] });
    },
  });

export const useUpdateAppLogo = () =>
  useMutation({
    mutationFn: (data: UpdateAppLogoRequest) => updateAppLogo(data),
    onSuccess(_, { id }) {
      queryClient.invalidateQueries({ queryKey: ['my-apps'] });
      queryClient.invalidateQueries({ queryKey: ['app', id.toString()] });
    },
  });

export const useAppScreenshotFiles = (appId: string | number, files: S3FileModel[] | S3FileModel | undefined) =>
  useQuery({
    queryFn: async () => {
      if (files === undefined) {
        return [];
      }

      const fileList = Array.isArray(files) ? files : [files];

      const fileObjects = await Promise.all(fileList.map((s) => fetchScreenshot(s)));
      return fileObjects.filter(Boolean) as File[];
    },
    queryKey: ['app', 'files', appId.toString(), files],
    enabled: files !== undefined,
  });
