import { useMutation, useQuery } from '@tanstack/react-query';
import { login } from './api';
import { clearStorage, getUserAccount, setAuthToken, setRefreshToken, setUserAccount } from '../../services/auth/tokenStorage';
import { ACCOUNT_QUERY_KEY } from '../../services/auth/queryKeys';
import { queryClient } from '../../services/api/queryClient';

export const useLogin = () =>
  useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) => login(email, password),
    onSuccess(data) {
      if (!data) return;

      setAuthToken(data.authTokens.auth);
      setRefreshToken(data.authTokens.refresh);
      setUserAccount(data.account);

      queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
    },
  });

export const useAccount = () => {
  const { data } = useQuery({
    queryFn: () => getUserAccount() ?? null,
    queryKey: [ACCOUNT_QUERY_KEY],
  });

  return data;
};

export const useLogout = () =>
  useMutation({
    mutationFn: async () => clearStorage(),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [ACCOUNT_QUERY_KEY] });
    },
  });
