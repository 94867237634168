import { OuterLink, Typography } from '@prepaire/ui-kit';
import { useTranslation } from 'react-i18next';
import { getName } from '@shared/utils/getName';
import { AppRequestModel, AppRequestStatusEnum } from '@entities/app-request/types';
import { useIsMutatingAppRequestStatus } from '@features/app-request-management/queries';
import { AppRequestStatus } from '@features/app-request-management/components/AppRequestStatus';
import styles from './AppRequestDetails.module.scss';
import { DeclineRequest } from './DeclineRequest';
import { ApproveRequest } from './ApproveRequest';

export interface AppRequestDetailsProps {
  appRequest: AppRequestModel;
}

export const AppRequestDetails = ({ appRequest }: AppRequestDetailsProps) => {
  const { t } = useTranslation('appRequestManagement');
  const isMutatingStatus = useIsMutatingAppRequestStatus();

  return (
    <section>
      <Typography className={styles.title} variant="h1">
        {t('requestTitle', { name: appRequest.app.name })}
      </Typography>
      <div className={styles.heading}>
        {appRequest.app.logo && (
          <img className={styles.logo} width={128} height={128} src={appRequest.app.logo.url} alt={appRequest.app.name} />
        )}
        <div>
          <div className={styles.nameEdit}>
            <Typography variant="h4">{getName(appRequest.app.creator)}</Typography>
          </div>
          <Typography variant="caption">{appRequest.app.creator.email}</Typography>
          <AppRequestStatus status={appRequest.status} />
        </div>
        <div className={styles.controls}>
          {appRequest.status === AppRequestStatusEnum.Pending && (
            <>
              <ApproveRequest requestId={appRequest.id} appName={appRequest.app.name} disabled={isMutatingStatus} />
              <DeclineRequest requestId={appRequest.id} disabled={isMutatingStatus} />
            </>
          )}
          <OuterLink className={styles.code} href="#">
            <Typography variant="body1">{t('sourceLink')}</Typography>
          </OuterLink>
        </div>
      </div>
      <Typography className={styles.description} variant="body1" component="p">
        {appRequest.app.description}
      </Typography>
      <div className={styles.details}>
        <Typography variant="h4">{t('technicalDetails')}</Typography>
        <Typography variant="body1">{appRequest.app.technicalDetails}</Typography>
      </div>
      <Typography variant="h4" className={styles.screenshotsTitle}>
        {t('screenshots')}
      </Typography>
      <div className={styles.screenshotList}>
        {appRequest.app.images.map((screenshot, idx) => (
          <img className={styles.screenshot} src={screenshot.url} alt={screenshot.name} key={`${screenshot}-${idx}`} />
        ))}
      </div>
    </section>
  );
};
