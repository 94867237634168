import { createFileRoute, Navigate, Outlet } from '@tanstack/react-router';
import { useCan } from '@shared/services/ability';
import { Action, Subject } from '@services/ability/parameters';
import { SIGNIN_PAGE_URL } from '@shared/services/service-urls';

function Auth() {
  const canView = useCan(Action.Read, Subject.IndexPage);
  if (!canView) {
    return <Navigate to={SIGNIN_PAGE_URL} />;
  }
  return <Outlet />;
}

export const Route = createFileRoute('/_auth')({
  component: Auth,
});
