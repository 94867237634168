import { z } from 'zod';

export const TEXT_FIELD_MAX = 1000;

export const appUpdateSchema = z.object({
  name: z.string().min(1).max(TEXT_FIELD_MAX),
  technicalDetails: z.string().min(1).max(TEXT_FIELD_MAX),
  description: z.string().min(1).max(TEXT_FIELD_MAX),
});

export type AppUpdateFormData = z.infer<typeof appUpdateSchema>;
