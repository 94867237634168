import { useSuspenseApp } from '@features/app-management/queries';
import { AppUpdateForm } from '@features/app-management/components/AppUpdateForm';

interface EditAppProps {
  appId: string;
}

export function EditAppPage({ appId }: EditAppProps) {
  const { data: app } = useSuspenseApp(appId);

  if (!app) {
    return null;
  }

  return (
    <AppUpdateForm
      appId={app.id}
      appName={app.name}
      appDescription={app.description}
      appTechnicalDetails={app.technicalDetails}
    />
  );
}
