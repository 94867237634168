const common = {
  navAppManagement: 'App management',
  navAppRequests: 'App Requests',
  navAppReviewRequests: 'App Review Requests',

  underConstr: 'Under Construction',

  ok: 'Ok',
  cancel: 'Cancel',
} as const;

export default common;
