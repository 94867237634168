import React from 'react';
import ReactDOM from 'react-dom/client';
// this is a service worker that mocks API and it's development only
import { enableMocking } from './services/mock-service-worker/setup';
import { App } from './components/App/App';
import './services/i18n/i18n';

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
});
