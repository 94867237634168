import { axiosInstance } from '../../services/api/axiosInstance';
import { endpoints } from '../../services/auth/endpoints';
import { LoginResponse } from '../../services/auth/types';

export const login = async (email: string, password: string): Promise<LoginResponse | undefined> => {
  const response = await axiosInstance.post(endpoints.login.expand({}), {
    email,
    pass: password,
  });

  return response.data;
};
