import { Typography } from '@prepaire/ui-kit';
import { Link } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { S3FileModel } from '@shared/entities/s3-file/types';
import styles from './AppRequestSummary.module.scss';

export interface AppRequestSummaryProps {
  reqId: string | number;
  devName: string;
  appName: string;
  description: string;
  logo?: S3FileModel | null;
  date?: string;
}

export const AppRequestSummary = ({ devName, appName, description, logo, date, reqId }: AppRequestSummaryProps) => {
  return (
    <article className={styles.summary}>
      {logo && <img className={styles.logo} width={56} height={56} src={logo.url} alt={appName} />}
      <div className={styles.info}>
        <Link to="/admin/app-requests/$reqId" params={{ reqId: reqId.toString() }}>
          <Typography className={styles.appName} variant="bodyImportant">
            {appName}
          </Typography>
        </Link>

        <Typography variant="body1">{devName}</Typography>
        <Typography className={styles.description} variant="body1">
          {description}
        </Typography>
      </div>
      {date && (
        <Typography className={styles.date} variant="body1">
          {dayjs(date).format('MMMM DD, YYYY')}
        </Typography>
      )}
    </article>
  );
};
