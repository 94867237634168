import { z } from 'zod';
import { AppReviewRequestResponseSchema } from '@entities/app-review-request/types';

/* --------------------------- APP REQUEST --------------------------- */

export const AppReviewRequestsResponseSchema = z.array(AppReviewRequestResponseSchema);
export type AppReviewRequestsResponse = z.infer<typeof AppReviewRequestsResponseSchema>;

/* --------------------------- APP REQUEST STATUS --------------------------- */

export interface UpdateAppReviewRequestStatusRequest {
  id: string | number;
  status: 'accept' | 'decline';
}
