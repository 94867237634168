import { axiosInstance } from '@shared/services/api/axiosInstance';
import {
  AppRequestsResponseSchema,
  UpdateAppRequestStatusRequest,
  UpdateAppRequestStatusSchema,
} from '@features/app-request-management/types';
import { objectToCamel } from 'ts-case-convert';
import { AppRequestModel, AppRequestResponseSchema } from '@entities/app-request/types';
import { logZodError } from '@shared/utils/logZodError';
import { endpoints } from './endpoints';

export const fetchAppRequests = async (): Promise<AppRequestModel[] | undefined> => {
  const { data } = await axiosInstance.get(endpoints.requests.expand({}));

  try {
    const appResponse = AppRequestsResponseSchema.parse(data);
    return appResponse.map(objectToCamel);
  } catch (e) {
    logZodError(e);
    throw e;
  }
};

export const fetchAppRequest = async (id: string): Promise<AppRequestModel | undefined> => {
  const { data } = await axiosInstance.get(endpoints.request.expand({ id }));

  try {
    const appResponse = AppRequestResponseSchema.parse(data);
    return objectToCamel(appResponse);
  } catch (e) {
    logZodError(e);
    throw e;
  }
};

export const updateAppRequestStatus = async ({
  id,
  status,
  feedback_message,
}: UpdateAppRequestStatusRequest): Promise<AppRequestModel | undefined> => {
  const { data } = await axiosInstance.post(endpoints.status.expand({ id }), {
    status,
    feedback_message,
  });

  try {
    const response = UpdateAppRequestStatusSchema.parse(data);
    return objectToCamel(response);
  } catch (e) {
    logZodError(e);
    throw e;
  }
};
