import { z } from 'zod';

export const TEXT_FIELD_MAX = 1000;

export const appReleaseSchema = z.object({
  version: z.string().min(1).max(TEXT_FIELD_MAX),
  changelogs: z.string().min(1).max(TEXT_FIELD_MAX),
  releaseNotes: z.string().min(1).max(TEXT_FIELD_MAX),
  fileOrLink: z.union([z.instanceof(File), z.string().min(1)]),
});

export type AppReleaseFormData = z.infer<typeof appReleaseSchema>;
