// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, FormField, Header, Icon, Input, Typography } from '@prepaire/ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { useAccount, useLogin } from '../../features/auth/queries';

import styles from './Signin.module.scss';

export interface ISigninProps {
  redirectAfterLoginNode: ReactNode;
}

export function Signin({ redirectAfterLoginNode }: ISigninProps) {
  const userAccount = useAccount();
  const { mutate: login } = useLogin();
  const { t } = useTranslation('auth');

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({ defaultValues: { email: '', password: '' } });

  const handleLogin = (data: { email: string; password: string }) => {
    login(data);
  };

  if (userAccount) {
    return redirectAfterLoginNode;
  }

  return (
    <div>
      <Header start={<Icon.PrepaireLogo />} />
      <main className={styles.main}>
        <Typography variant="h1" className={styles.title}>
          {t('signInTitle')}
        </Typography>
        <form onSubmit={handleSubmit(handleLogin)}>
          <FormField name="email" label={<Typography variant="h4">{t('emailLabel')}</Typography>}>
            <Controller
              rules={{ required: true }}
              control={control}
              name="email"
              render={({ field }) => <Input {...field} placeholder={t('emailPh')} />}
            />
          </FormField>
          <FormField name="password" label={<Typography variant="h4">{t('passLabel')}</Typography>}>
            <Controller
              rules={{ required: true }}
              control={control}
              name="password"
              render={({ field }) => <Input type="password" {...field} placeholder={t('passPh')} />}
            />
          </FormField>
          <Button type="submit" variant="primary" className={styles.submit} disabled={!isValid}>
            {t('signInBtn')}
          </Button>
        </form>
      </main>
      <Typography variant="h5" className={styles.appVersion}>
        version: {import.meta.env.VITE_APP_VERSION}
      </Typography>
    </div>
  );
}
