import { AbilityBuilder, defineAbility, MongoAbility } from '@casl/ability';
import { UserRole } from '@shared/entities/user/types';
import { TAppUser } from '@shared/services/ability/types';
import { Action, Subject } from './parameters';

/* eslint-disable */
// eslint is disabled in order to preserve formatting
const rolePermissions = {
  [UserRole.ADMIN](allow: AbilityBuilder<MongoAbility>["can"]) {
    //"manage" and "all" are keywords in CASL
    allow("manage", "all");
  },

  [UserRole.MEMBER](allow: AbilityBuilder<MongoAbility>["can"]) {
    allow(Action.Read, Subject.IndexPage);
    allow(Action.Create, Subject.App);
  },
};
/* eslint-enable */

const ALL_KNOWN_ROLES = new Set(Object.values(UserRole));

const isKnownRole = (role: UserRole) => ALL_KNOWN_ROLES.has(role);

export const defineAbilityFor = (user: TAppUser | undefined | null) =>
  defineAbility((allow, forbid) => {
    if (!user || !user.role || !isKnownRole(user.role)) {
      // "manage" and "all" are keywords in CASL
      forbid('manage', 'all');
      return;
    }

    const { role } = user;

    // setting up permissions by role
    rolePermissions[role](allow);
  });
