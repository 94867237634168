import { useSuspenseApp } from '@features/app-management/queries';
import { AppReleaseForm } from '@features/app-management/components/AppReleaseForm';

interface ReleaseAppPageProps {
  appId: string;
}

export function ReleaseAppPage({ appId }: ReleaseAppPageProps) {
  const { data: app } = useSuspenseApp(appId);

  if (!app) {
    return null;
  }

  return <AppReleaseForm appId={appId} appName={app.name} />;
}
