import { getName } from '@shared/utils/getName';
import clsx from 'clsx';
import { AppReviewRequestModel } from '@entities/app-review-request/types';
import { AppReviewRequestSummary } from '@features/app-feedback-moderation/components/AppReviewRequestSummary';
import styles from './IncomingAppReviews.module.scss';

export interface AppRequestsProps {
  reviews: AppReviewRequestModel[];
  className?: string;
}

export const IncomingAppReviews = ({ className, reviews }: AppRequestsProps) => {
  return (
    <section className={clsx(className, styles.requests)}>
      {reviews.map((item) => (
        <AppReviewRequestSummary
          key={item.id}
          reqId={item.id}
          authorName={getName(item.review.creator)}
          authorAvatar={item.review.creator.avatar}
          text={item.review.text}
          date={item.createdAt}
          rating={item.review.rating}
        />
      ))}
    </section>
  );
};
