import { createFileRoute } from '@tanstack/react-router';
import { AppReviewsModerationPage } from '@pages/app-feedback-moderation/AppReviewsModerationPage/AppReviewsModerationPage';
import { ensureAppReviewRequests } from '@features/app-feedback-moderation/queries';

export const Route = createFileRoute('/_auth/_main/admin/_admin/app-reviews/')({
  loader: () => ensureAppReviewRequests(),
  pendingComponent: () => <div>PENDING</div>,
  errorComponent: () => <div>ERROR</div>,
  component: AppReviewsModerationPage,
});
