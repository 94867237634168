import { useQuery } from '@tanstack/react-query';
import { queryClient } from '@shared/services/api/queryClient';
import { fetchFeedback } from './api';

export const ensureAppFeedback = (id: string) =>
  queryClient.ensureQueryData({
    queryFn: () => fetchFeedback(id),
    queryKey: ['app-feedback', id],
  });

export const useAppFeedback = (id: string) =>
  useQuery({
    queryFn: () => fetchFeedback(id),
    queryKey: ['app-feedback', id],
  });
