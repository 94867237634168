import { axiosInstance } from '@shared/services/api/axiosInstance';
import { objectToCamel } from 'ts-case-convert';
import { logZodError } from '@shared/utils/logZodError';
import { AppReviewModel } from '@shared/entities/feedback/types';
import { endpoints } from './endpoints';
import { AppFeedbackResponseSchema } from './types';

export const fetchFeedback = async (id: string): Promise<AppReviewModel[] | undefined> => {
  const { data } = await axiosInstance.get(endpoints.appFeedback.expand({ id }));

  try {
    const appsResponse = AppFeedbackResponseSchema.parse(data);
    return appsResponse.map(objectToCamel);
  } catch (e) {
    logZodError(e);
    throw e;
  }
};
