import { Button, useFailDialog, useSuccessDialog } from '@prepaire/ui-kit';
import { useTranslation } from 'react-i18next';
import { useUpdateReviewRequestStatus } from '@features/app-feedback-moderation/queries';

export interface DeclineRequestProps {
  requestId: string | number;
  disabled?: boolean;
}

export const DeclineRequest = ({ requestId, disabled }: DeclineRequestProps) => {
  const { t } = useTranslation('appReviewRequestManagement');
  const { t: tC } = useTranslation('common');

  const { mutateAsync, isPending } = useUpdateReviewRequestStatus();

  const [successDialog, toggleSuccessDialog] = useSuccessDialog({ okText: tC('ok'), content: t('rejectSuccessText') });
  const [failDialog, toggleFailDialog] = useFailDialog({ okText: tC('ok'), content: t('rejectErrorText') });

  const handleReject = async () => {
    try {
      await mutateAsync({ id: requestId, status: 'decline' });
      toggleSuccessDialog();
    } catch (error) {
      toggleFailDialog();
    }
  };

  return (
    <>
      {successDialog}
      {failDialog}
      <Button onClick={handleReject} disabled={isPending || disabled} loader={isPending}>
        {t('rejectBtn')}
      </Button>
    </>
  );
};
