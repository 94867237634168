export async function enableMocking() {
  const { VITE_MOCK_SERVICE_WORKER, DEV } = import.meta.env;
  if (VITE_MOCK_SERVICE_WORKER === 'all' || (DEV && VITE_MOCK_SERVICE_WORKER === 'dev')) {
    const { worker } = await import('./browser');

    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start();
  }
  return undefined;
}
