import { Typography } from '@prepaire/ui-kit';
import { useTranslation } from 'react-i18next';
import styles from './Welcome.module.scss';

export function Welcome() {
  const { t } = useTranslation();

  return (
    <section className={styles.welcome}>
      <Typography variant="h1">{t('underConstr')}</Typography>
    </section>
  );
}
