const devAgreement = {
  title: 'Agreement is required',

  subTitle: 'Ensure that you upload an agreement with Prepaire before submitting an application to the app store',

  fileLabel: 'Upload the agreement file',
  checkLabel: 'I agree to the terms & conditions',

  submitBtn: 'Save',

  successText: 'Uploaded successfully. You will be redirected to the app management page in {{count}} seconds',
  errorText: 'Could not upload data due to error',
} as const;

export default devAgreement;
