import { createFileRoute } from '@tanstack/react-router';
import { EditAppPage } from '@pages/app-management/EditAppPage';
import { ensureApp } from '@features/app-management/queries';

export const Route = createFileRoute('/_auth/_main/apps/$appId/edit')({
  loader: ({ params }) => ensureApp(params.appId),
  pendingComponent: () => <div>PENDING</div>,
  errorComponent: () => <div>ERROR</div>,
  component: function EditComponent() {
    const { appId } = Route.useParams();

    return <EditAppPage appId={appId} />;
  },
});
