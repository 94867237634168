const common = {
  signInTitle: '',

  emailLabel: 'Email',
  emailPh: 'youremail@example.com',

  passLabel: 'Password',
  passPh: 'Your password',

  signInBtn: 'Log in',
} as const;

export default common;
