import { z } from 'zod';
import { AppReviewModel, AppReviewResponseSchema } from '@shared/entities/feedback/types';
import { AppResponseSchema, AppSummaryModel } from '@shared/entities/app/types';

export interface AppReviewRequestModel {
  id: string | number;
  review: AppReviewModel;
  app: AppSummaryModel;
  createdAt?: string;
}

export const AppReviewRequestResponseSchema = z.object({
  id: z.union([z.string(), z.number()]),
  review: AppReviewResponseSchema,
  app: AppResponseSchema,
  created_at: z.string().optional(),
});
