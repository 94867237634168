import { useTranslation } from 'react-i18next';
import { Link, Outlet } from '@tanstack/react-router';
import styles from './Apps.module.scss';

export function Apps() {
  const { t } = useTranslation('appManagement');

  return (
    <div>
      <div className={styles.nav}>
        <Link to="/apps" className={styles.link}>
          {t('navMyApps')}
        </Link>
        <Link to="/apps/upload" className={styles.link}>
          {t('navAppUpload')}
        </Link>
      </div>
      <Outlet />
    </div>
  );
}
