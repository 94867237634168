import { useTranslation } from 'react-i18next';
import { Button, useConfirmDialog } from '@prepaire/ui-kit';
import { useUpdateReviewRequestStatus } from '@features/app-feedback-moderation/queries';

export interface ApproveRequestProps {
  requestId: string | number;
  disabled?: boolean;
}

export const ApproveRequest = ({ requestId, disabled }: ApproveRequestProps) => {
  const { t } = useTranslation('appReviewRequestManagement');
  const { t: tC } = useTranslation('common');

  const {
    mutateAsync: approveAppRequest,
    status: approveMutationStatus,
    reset: resetApproveMutation,
    isPending,
  } = useUpdateReviewRequestStatus();

  const [dialog, toggleApproveDialog] = useConfirmDialog({
    okText: t('approveBtn'),
    cancelText: tC('cancel'),
    closeText: tC('ok'),
    content: t('approveText'),
    errorContent: t('approveErrorText'),
    successContent: t('approveSuccessText'),
    status: approveMutationStatus,
    async onOk() {
      await approveAppRequest({ id: requestId, status: 'accept' });
    },
    afterClose() {
      if (!isPending) {
        resetApproveMutation();
      }
    },
  });

  return (
    <>
      {dialog}
      <Button variant="primary" onClick={() => toggleApproveDialog(true)} loader={isPending} disabled={isPending || disabled}>
        {t('approveBtn')}
      </Button>
    </>
  );
};
