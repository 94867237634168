import { z } from 'zod';
import { CreatorModel, CreatorResponseSchema } from '../creator/types';
import { S3FileModel, S3FileSchema } from '../s3-file/types';

export enum AppStatusEnum {
  Draft = 'Draft',
  Active = 'Active',
  Pending = 'Pending',
  NeedsAttention = 'needsAttention',
}

export interface AppSummaryModel {
  id: string | number;
  name: string;
  status?: AppStatusEnum;
  logo?: S3FileModel | null;
  technicalDetails: string;
  description: string;
  creator: CreatorModel;
  images: S3FileModel[];
  runCount: number;
}

export const AppResponseSchema = z.object({
  id: z.union([z.string(), z.number()]),
  name: z.string(),
  description: z.string(),
  status: z.nativeEnum(AppStatusEnum).optional(),
  technical_details: z.string(),
  creator: CreatorResponseSchema,
  images: z.array(S3FileSchema),
  logo: S3FileSchema.optional().nullable(),
  run_count: z.number(),
});
