import { Typography, Rating, Icon } from '@prepaire/ui-kit';
import { S3FileModel } from '@shared/entities/s3-file/types';
import styles from './UserComment.module.scss';

export interface UserCommentProps {
  text: string;
  userName: string;
  userAvatar?: S3FileModel;
  rating: number;
  maxRating?: number;
}

export function UserComment({ text, userAvatar, userName, rating, maxRating = 5 }: UserCommentProps) {
  return (
    <article className={styles.wrapper}>
      <div className={styles.comment}>
        {userAvatar?.url ? (
          <img className={styles.avatar} width={55} height={55} src={userAvatar.url} alt={userName} />
        ) : (
          <Icon.Profile className={styles.avatar} width={55} height={55} />
        )}
        <div className={styles.info}>
          <Typography variant="h5" component="p">
            {userName}
          </Typography>
          <Typography variant="caption">month ago</Typography>
          <Rating className={styles.rating} maxRating={maxRating} rating={rating} />
        </div>
        <Typography className={styles.text} variant="body1">
          {text}
        </Typography>
      </div>
    </article>
  );
}
