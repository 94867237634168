import { Button, DialogModal, Input, Typography, useDialog } from '@prepaire/ui-kit';
import { useTranslation } from 'react-i18next';
import { useUpdateAppRequestStatus } from '@features/app-request-management/queries';
import { AppRequestStatusEnum } from '@entities/app-request/types';
import { Controller, useForm } from 'react-hook-form';
import {
  DeclineRequestFormData,
  declineRequestSchema,
  REASON_CHARACTER_LIMIT,
} from '@features/app-request-management/components/AppRequestDetails/DeclineRequest/formSchema';
import { zodResolver } from '@hookform/resolvers/zod';

import styles from './DeclineRequest.module.scss';

export interface DeclineRequestProps {
  requestId: string | number;
  disabled?: boolean;
}

export const DeclineRequest = ({ requestId, disabled }: DeclineRequestProps) => {
  const { t } = useTranslation('appRequestManagement');
  const { t: tC } = useTranslation('common');

  const { mutateAsync, isPending, isError } = useUpdateAppRequestStatus();

  const [dialogProps, toggleDialog] = useDialog({});

  const { handleSubmit, control } = useForm<DeclineRequestFormData>({
    resolver: zodResolver(declineRequestSchema),
  });

  const handleReject = async () => {
    try {
      await mutateAsync({ id: requestId, status: AppRequestStatusEnum.Declined });
      // toggleSuccessDialog();
    } catch (error) {
      // toggleFailDialog();
    }
  };

  return (
    <>
      <DialogModal {...dialogProps} closable={!isPending} maskClosable={!isPending}>
        <form onSubmit={handleSubmit(handleReject)} className={styles.form}>
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <Input.Textarea {...field} maxLength={REASON_CHARACTER_LIMIT} placeholder={t('rejectPhText')} />
            )}
          />
          <div className={styles.formControls}>
            <Button type="submit" variant="primary" disabled={isPending} loader={isPending}>
              {tC('ok')}
            </Button>
            <Button onClick={() => toggleDialog(false)} disabled={isPending}>
              {tC('cancel')}
            </Button>
          </div>
        </form>
        {isError && (
          <Typography variant="body1" component="p" className={styles.error}>
            {t('rejectErrorText')}
          </Typography>
        )}
      </DialogModal>
      <Button onClick={() => toggleDialog(true)} disabled={isPending || disabled} loader={isPending}>
        {t('rejectBtn')}
      </Button>
    </>
  );
};
