import { Icon, Typography } from '@prepaire/ui-kit';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { getName } from '@shared/utils/getName';
import { AppSummaryModel } from '@shared/entities/app/types';
import styles from './AppDetails.module.scss';

export interface AppDetailsProps {
  app: AppSummaryModel;
}

export const AppDetails = ({ app }: AppDetailsProps) => {
  const { t } = useTranslation('appManagement');

  return (
    <section>
      <Typography className={styles.title} variant="h1">
        {app.name}
      </Typography>
      <div className={styles.heading}>
        <div className={styles.logoWrapper}>
          {app.logo && <img className={styles.logo} width={128} height={128} src={app.logo.url} alt={app.name} />}
          <Link className={styles.logoEditLink} to="/apps/$appId/logo" params={{ appId: app.id.toString() }}>
            <Icon.Pencil className={styles.logoEditIcon} />
          </Link>
        </div>
        <div>
          <div className={styles.nameAndControls}>
            <Typography variant="h4">{getName(app.creator)}</Typography>
            <Link to="/apps/$appId/edit" params={{ appId: app.id.toString() }}>
              <Icon.Pencil />
            </Link>
            <Link className={styles.release} to="/apps/$appId/release" params={{ appId: app.id.toString() }}>
              <Typography variant="body1">{t('newRelease')}</Typography>
            </Link>
          </div>
          <Typography variant="caption">{app.creator.email}</Typography>
        </div>
      </div>
      <Typography className={styles.description} variant="body1" component="p">
        {app.description}
      </Typography>
      <div className={styles.screenshotsTitle}>
        <Typography variant="h4">{t('screenshots')}</Typography>
        <Link to="/apps/$appId/screenshots" params={{ appId: app.id.toString() }}>
          <Icon.Pencil />
        </Link>
      </div>
      <div className={styles.screenshotList}>
        {app.images.map((screenshot, idx) => (
          <img className={styles.screenshot} src={screenshot.url} alt={screenshot.name} key={`${screenshot}-${idx}`} />
        ))}
      </div>
    </section>
  );
};
