import { PropsWithChildren, useMemo } from 'react';
import { MongoAbility } from '@casl/ability';
import { AbilityContext } from './AbilityContext';
import { useAccount } from '../../features/auth/queries';
import { TAppUser } from './types';

export interface AbilityProviderProps {
  defineAbility(account: TAppUser | undefined | null): MongoAbility;
}

export function AbilityProvider({ children, defineAbility }: PropsWithChildren<AbilityProviderProps>) {
  const account = useAccount();
  const ability = useMemo(() => defineAbility(account), [account, defineAbility]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
}
