import { Button, FormField, Input, Typography, useFailDialog } from '@prepaire/ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from '@tanstack/react-router';
import { useAccount } from '@shared/features/auth/queries';
import { useUpdateAppImages } from '../../queries';
import { AppScreenshotUpdateFormData, appScreenshotUpdateSchema } from './formSchema';

import styles from './AppScreenshotUpdateForm.module.scss';

interface AppScreenshotUpdateFormProps {
  appId: string | number;
  appName: string;
  screenshots: File[];
}

export function AppScreenshotUpdateForm({ appName, screenshots, appId }: AppScreenshotUpdateFormProps) {
  const { t } = useTranslation('appUpdate');
  const { t: tC } = useTranslation('common');

  const user = useAccount();
  const { mutateAsync: updateApp, isPending } = useUpdateAppImages();

  const navigate = useNavigate();

  const [failDialog, toggleFailDialog] = useFailDialog({
    okText: tC('ok'),
    content: t('errorText'),
  });

  const { handleSubmit, control } = useForm<AppScreenshotUpdateFormData>({
    resolver: zodResolver(appScreenshotUpdateSchema),
    defaultValues: {
      screenshots,
    },
  });

  const onSubmit = async (formData: AppScreenshotUpdateFormData) => {
    if (!user) {
      return;
    }

    try {
      await updateApp({
        id: appId,
        images: formData.screenshots,
        creator_id: user.id,
      });
    } catch (error) {
      toggleFailDialog();
      return;
    }

    navigate({ to: '/apps/$appId', params: { appId: appId.toString() } });
  };

  return (
    <main className={clsx(styles.appUpdate)}>
      {failDialog}
      <Typography variant="h1" className={styles.title}>
        {t('screenshotTitle', { name: appName })}
      </Typography>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          className={styles.screenshotsField}
          name="screenshots"
          label={<Typography variant="h4">{t('screenshotsLabel')}</Typography>}
        >
          <Controller
            name="screenshots"
            control={control}
            render={({ field }) => (
              <Input.Images
                classNames={{ previewList: styles.screenshotsPreviewList }}
                onChange={field.onChange}
                maxFiles={10}
                defaultFiles={screenshots}
              />
            )}
          />
        </FormField>

        <div className={styles.formBottom}>
          <Button type="submit" variant="primary" disabled={isPending} loader={isPending}>
            {t('submitBtn')}
          </Button>
        </div>
      </form>
    </main>
  );
}
