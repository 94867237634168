import { AppRequestDetails } from '@features/app-request-management/components/AppRequestDetails';
import { useSuspenseAppRequest } from '@features/app-request-management/queries';

export interface AppRequestProps {
  appRequestId: string;
}

export const AppRequestPage = ({ appRequestId }: AppRequestProps) => {
  const { data: appRequest } = useSuspenseAppRequest(appRequestId);

  if (!appRequest) {
    return null;
  }

  return (
    <main>
      <AppRequestDetails appRequest={appRequest} />
    </main>
  );
};
