import urlJoin from 'url-join';
import { APP_MANAGEMENT_SERVICE_URL } from '@services/service-urls';
import { parseEndpoint } from '@shared/utils/parseEndpoint';

export const endpoints = {
  apps: parseEndpoint(urlJoin(APP_MANAGEMENT_SERVICE_URL, 'apps')),
  app: parseEndpoint(urlJoin(APP_MANAGEMENT_SERVICE_URL, 'apps', '{id}')),
  release: parseEndpoint(urlJoin(APP_MANAGEMENT_SERVICE_URL, 'apps/{id}/releases')),
  images: parseEndpoint(urlJoin(APP_MANAGEMENT_SERVICE_URL, 'apps/{id}/images{?creator_id}')),
  logo: parseEndpoint(urlJoin(APP_MANAGEMENT_SERVICE_URL, 'apps/{id}/logo{?creator_id}')),
} as const;
