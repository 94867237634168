import { createFileRoute } from '@tanstack/react-router';
import { EditAppScreenshotsPage } from '@pages/app-management/EditAppScreenshotsPage';

export const Route = createFileRoute('/_auth/_main/apps/$appId/screenshots')({
  component: function EditComponent() {
    const { appId } = Route.useParams();

    return <EditAppScreenshotsPage appId={appId} />;
  },
});
