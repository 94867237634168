import { axiosInstance } from '@shared/services/api/axiosInstance';
import { objectToCamel } from 'ts-case-convert';
import { AppResponseSchema, AppSummaryModel } from '@shared/entities/app/types';
import { logZodError } from '@shared/utils/logZodError';
import { S3FileModel } from '@shared/entities/s3-file/types';
import { endpoints } from './endpoints';
import {
  MyAppsResponseSchema,
  ReleaseAppRequest,
  ReleaseAppResponse,
  UpdateAppImagesRequest,
  UpdateAppLogoRequest,
  UpdateAppRequest,
  UpdateAppResponse,
  UploadAppRequest,
  UploadAppResponse,
} from './types';

export const uploadApp = async (data: UploadAppRequest): Promise<UploadAppResponse | undefined> => {
  const response = await axiosInstance.postForm(endpoints.apps.expand({}), data, {
    formSerializer: {
      indexes: null,
    },
    onUploadProgress(_) {},
  });

  return response.data;
};

export const fetchMyApps = async (): Promise<AppSummaryModel[] | undefined> => {
  const { data } = await axiosInstance.get(endpoints.apps.expand({}));

  try {
    const appsResponse = MyAppsResponseSchema.parse(data);
    return appsResponse.map(objectToCamel);
  } catch (e) {
    logZodError(e);
    throw e;
  }
};

export const fetchApp = async (id: string): Promise<AppSummaryModel | undefined> => {
  const { data } = await axiosInstance.get(endpoints.app.expand({ id }));

  try {
    const appResponse = AppResponseSchema.parse(data);
    return objectToCamel(appResponse);
  } catch (e) {
    logZodError(e);
    throw e;
  }
};

export const releaseApp = async ({ id, ...requestData }: ReleaseAppRequest): Promise<ReleaseAppResponse | undefined> => {
  const response = await axiosInstance.postForm(endpoints.release.expand({ id }), requestData);

  return response.data;
};

export const updateApp = async ({ id, ...requestData }: UpdateAppRequest): Promise<UpdateAppResponse | undefined> => {
  const response = await axiosInstance.put(endpoints.app.expand({ id }), requestData);

  return response.data;
};

export const updateAppImages = async ({
  id,
  creator_id,
  ...requestData
}: UpdateAppImagesRequest): Promise<UpdateAppResponse | undefined> => {
  const response = await axiosInstance.postForm(endpoints.images.expand({ id, creator_id }), requestData, {
    formSerializer: {
      indexes: null,
    },
  });

  return response.data;
};

export const updateAppLogo = async ({ id, creator_id, logo }: UpdateAppLogoRequest): Promise<UpdateAppResponse | undefined> => {
  const response = await axiosInstance.postForm(endpoints.logo.expand({ id, creator_id }), { file: logo });

  return response.data;
};

export const fetchScreenshot = async (fileModel: S3FileModel): Promise<File | undefined> => {
  const { data } = await axiosInstance.get(fileModel.url, {
    responseType: 'blob',
    headers: { Authorization: '0' },
  });
  if (!data) {
    return undefined;
  }

  return new File([data], fileModel.name, { type: fileModel.fileType });
};

export const deleteApp = async (id: string): Promise<ReleaseAppResponse | undefined> => {
  const response = await axiosInstance.delete(endpoints.app.expand({ id }));

  return response.data;
};

// this is only for dev purposes
// eslint-disable-next-line
(window as any).__deleteApp = deleteApp;
