import { Button, Dropdown, Header, OuterLink, Sidebar, UserMenu, NavBar } from '@prepaire/ui-kit';
import urlJoin from 'url-join';
import { Link, Outlet } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useLogout } from '@shared/features/auth/queries';
import { useLanguagesList } from '@shared/utils/hooks/useLanguagesList';
import { PLATFORM_URL } from '@services/service-urls';

import { Can } from '@shared/services/ability';
import { Action, Subject } from '@services/ability/parameters';
import styles from './Main.module.scss';

export function Main() {
  const { t, i18n } = useTranslation();
  const { mutate: logOut } = useLogout();

  const languages = useLanguagesList();

  return (
    <div>
      <Sidebar className={styles.sidebar} rootUrl={PLATFORM_URL} />
      <div className={styles.content}>
        <Header
          start={
            <NavBar>
              <NavBar.Item>
                <Link to="/apps">{t('navAppManagement')}</Link>
              </NavBar.Item>
              <Can I={Action.Read} a={Subject.AppRequest}>
                <NavBar.Item>
                  <Link to="/admin/app-requests">{t('navAppRequests')}</Link>
                </NavBar.Item>
              </Can>
              <Can I={Action.Read} a={Subject.AppReviewRequest}>
                <NavBar.Item>
                  <Link to="/admin/app-reviews">{t('navAppReviewRequests')}</Link>
                </NavBar.Item>
              </Can>
            </NavBar>
          }
          end={
            <div className={styles.profile}>
              <Dropdown
                overlay={languages.map((lang) => (
                  <Dropdown.Item key={lang}>
                    <Button variant="passthrough" onClick={() => i18n.changeLanguage(lang)}>
                      {lang}
                    </Button>
                  </Dropdown.Item>
                ))}
              >
                <Button variant="passthrough">{i18n.language}</Button>
              </Dropdown>
              <UserMenu>
                <UserMenu.Item>
                  <OuterLink href={urlJoin(PLATFORM_URL, '/profile')}>Profile</OuterLink>
                </UserMenu.Item>
                <UserMenu.Item>
                  <Button variant="passthrough" onClick={() => logOut()}>
                    Logout
                  </Button>
                </UserMenu.Item>
              </UserMenu>
            </div>
          }
        />
        <div className={styles.pageWrapper}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
