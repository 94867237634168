import { AppResponseSchema, AppSummaryModel } from '@shared/entities/app/types';
import { z } from 'zod';

export enum AppRequestStatusEnum {
  Pending = 'pending',
  Declined = 'declined',
  Approved = 'approved',
  Returned = 'returned for revision',
}

export interface AppRequestModel {
  id: string | number;
  app: AppSummaryModel;
  status: AppRequestStatusEnum;
  createdAt?: string;
}

export const AppRequestResponseSchema = z.object({
  id: z.union([z.string(), z.number()]),
  app: AppResponseSchema,
  status: z.nativeEnum(AppRequestStatusEnum),
  created_at: z.string().optional(),
});
