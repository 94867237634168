import { Typography } from '@prepaire/ui-kit';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AppRequestStatusEnum } from '@entities/app-request/types';
import styles from './AppRequestStatus.module.scss';

export interface AppStatusProps {
  status: AppRequestStatusEnum;
  className?: string;
}

export function AppRequestStatus({ status, className }: AppStatusProps) {
  const { t } = useTranslation('appRequestManagement');

  return (
    <Typography
      variant="h5"
      component="p"
      className={clsx(className, styles.status, {
        [styles.statusApproved]: status === AppRequestStatusEnum.Approved,
        [styles.statusRejected]: status === AppRequestStatusEnum.Declined || status === AppRequestStatusEnum.Returned,
      })}
    >
      {status === AppRequestStatusEnum.Pending && t('statusPending')}
      {status === AppRequestStatusEnum.Returned && t('statusReturned')}
      {status === AppRequestStatusEnum.Approved && t('statusApproved')}
      {status === AppRequestStatusEnum.Declined && t('statusRejected')}
    </Typography>
  );
}
