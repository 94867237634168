import { QueryClientProvider } from '@tanstack/react-query';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { queryClient } from '@shared/services/api/queryClient';
import { AbilityProvider } from '@shared/services/ability';
import { defineAbilityFor } from '@services/ability/abilityDefinition';
// Import the generated route tree
import { routeTree } from '../../routeTree.gen';

import '../../styles/index.css';

// Create a new router instance
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export function App() {
  const { i18n } = useTranslation();
  const dir = i18n.dir();

  useEffect(() => {
    document.body.dir = dir;
  }, [dir]);

  return (
    <QueryClientProvider client={queryClient}>
      <AbilityProvider defineAbility={defineAbilityFor}>
        <RouterProvider router={router} />
        {/* <h1>Hey</h1> */}
      </AbilityProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
