const appUpload = {
  title: 'تحميل التطبيق',

  navMyApps: 'تطبيقي',
  navAppUpload: 'تحميل التطبيق',

  nameLabel: 'اسم التطبيق',
  namePh: 'أدخل اسم التطبيق',
  nameHint: 'قريبا!',

  infoLabel: 'معلومات التطبيق',
  infoPh: 'أدخل وصفاً تفصيلياً للتطبيق، مع التركيز على وظائفه وميزاته الرئيسية',
  infoHint: 'قريبا!',

  repoLabel: 'ملف التطبيق أو الرابط',
  repoPh: 'قم بتحميل ملف التطبيق أو توفير رابط لموقعه على خادم التطبيق',
  repoHint: 'قريبا!',

  descLabel: 'معلومات التطبيق',
  descPh: 'أرفق مستندًا يوضح الجوانب التقنية للتطبيق، بما في ذلك وظائفه ومتطلبات النظام وأي تبعيات',
  descHint: 'قريبا!',

  screenshotsLabel: 'لقطات شاشة',

  specsLink: 'مواصفات الحجم والصيغة',
  moderationDisclaimer: 'سيتم مراجعة التطبيق قبل التحميل',

  submitBtn: 'إرسال',

  progressMsg: 'جارٍ تحميل التطبيق...',

  success: 'تم تحميل جميع البيانات بنجاح',
} as const;

export default appUpload;
