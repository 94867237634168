import { Typography } from '@prepaire/ui-kit';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { AppStatusEnum } from '@shared/entities/app/types';
import { S3FileModel } from '@shared/entities/s3-file/types';
import styles from './AppSummary.module.scss';
import { AppStatus } from '../AppStatus';

export interface AppSummaryProps {
  id: string | number;
  name: string;
  description: string;
  logo?: S3FileModel | null;
  status?: AppStatusEnum;
  userName: string;
  runCount: number;
}

export function AppSummary({ name, status, id, logo, description, userName, runCount }: AppSummaryProps) {
  const { t } = useTranslation('appManagement');
  return (
    <article className={styles.appSummary}>
      <div className={styles.headingBlock}>
        {logo && <img className={styles.logo} width={100} height={100} src={logo.url} alt={name} />}
        <div className={styles.nameBlock}>
          <Link to="/apps/$appId" params={{ appId: id.toString() }}>
            <Typography className={styles.title} variant="h4">
              {name}
            </Typography>
          </Link>
          <AppStatus status={status} />
          <Typography variant="body1">{t('runs', { count: runCount })}</Typography>
        </div>
      </div>
      <div className={styles.descriptionBlock}>
        <Typography variant="h5" className={styles.userName}>
          {userName}
        </Typography>
        <Typography className={styles.description}>{description}</Typography>
      </div>
    </article>
  );
}
