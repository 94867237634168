import { z } from 'zod';
import { S3FileModel, S3FileSchema } from '../s3-file/types';

export const CreatorResponseSchema = z.object({
  id: z.union([z.string(), z.number()]),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  avatar: S3FileSchema.optional(),
});

export interface CreatorModel {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  avatar?: S3FileModel;
}
