const appReviewRequestManagement = {
  requestsTitle: 'New App Review Submissions',

  approveBtn: 'Approve',
  rejectBtn: 'Decline',

  approveText: 'Approve review?',
  approveSuccessText: 'Review approved successfully',
  approveErrorText: 'Could not approve the review request due to error',

  rejectSuccessText: 'Review was rejected',
  rejectErrorText: 'Could not reject the review request due to error',
} as const;

export default appReviewRequestManagement;
