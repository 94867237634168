import { startTransition, useCallback, useRef, useState } from 'react';

/**
 * Simulates progress
 * TODO: remove when BE is made and it returns actual progress
 * @param interval
 * @param corr
 */
export const useProgress = (interval: number, corr: number) => {
  const [progress, setProgress] = useState(0);
  const prevStep = useRef(0);
  const intervalRef = useRef<number | null>(null);

  const endProgress = useCallback(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    setProgress(1);
  }, []);

  const resetProgress = useCallback(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    setProgress(0);
    prevStep.current = 0;
  }, []);

  const step = useCallback(() => {
    prevStep.current += 1;
    const currentStep = prevStep.current;

    const nextProgress = currentStep / (corr + currentStep);

    startTransition(() => {
      setProgress(nextProgress);
    });
  }, [corr]);

  const startProgress = useCallback(() => {
    intervalRef.current = window.setInterval(step, interval);
  }, [step, interval]);

  return { startProgress, endProgress, progress, resetProgress };
};
