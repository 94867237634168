import common from './common';
import appUpload from './app-upload';
import validation from './validation';
import auth from './auth';
import appManagement from './app-management';
import appUpdate from './app-update';
import devAgreement from './dev-agreement';
import appRequestManagement from './app-request-management';
import appReviewRequestManagement from './app-review-request-management';

export const en = {
  common,
  appUpload,
  appUpdate,
  validation,
  auth,
  appManagement,
  devAgreement,
  appRequestManagement,
  appReviewRequestManagement,
};
