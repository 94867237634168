import { axiosInstance } from '@shared/services/api/axiosInstance';
import { objectToCamel } from 'ts-case-convert';
import { logZodError } from '@shared/utils/logZodError';
import { AppReviewRequestsResponseSchema, UpdateAppReviewRequestStatusRequest } from '@features/app-feedback-moderation/types';
import { AppReviewRequestModel } from '@entities/app-review-request/types';
import { endpoints } from './endpoints';

export const fetchAppReviewRequests = async (): Promise<AppReviewRequestModel[] | undefined> => {
  const { data } = await axiosInstance.get(endpoints.reviews.expand({}));

  try {
    const appResponse = AppReviewRequestsResponseSchema.parse(data);
    return appResponse.map(objectToCamel);
  } catch (e) {
    logZodError(e);
    throw e;
  }
};

export const updateAppReviewRequestStatus = async ({ id, status }: UpdateAppReviewRequestStatusRequest): Promise<undefined> => {
  await axiosInstance.post(endpoints.status.expand({ id }), { status });
};
