const appManagement = {
  navMyApps: 'My Apps',
  navAppUpload: 'App upload',

  myAppsTitle: 'My Apps',

  screenshots: 'Screenshots',

  runs: '{{count}} runs',

  statusActive: 'Active',
  statusPending: 'Pending',
  statusNeedsAttention: 'Needs Attention',
  statusDraft: 'Draft',

  newRelease: 'New Release',
} as const;

export default appManagement;
