import { z } from 'zod';
import { AppResponseSchema } from '@shared/entities/app/types';

/* --------------------------- UPLOAD APP --------------------------- */

export interface UploadAppRequest {
  name: string;
  creator_id: string;
  description: string;
  technical_details: string;
  release?: File | string;
  images: File[];
  logo?: File;
}

export interface UploadAppResponse {}

/* --------------------------- APP --------------------------- */

export const MyAppsResponseSchema = z.array(AppResponseSchema);

export type MyAppsResponse = z.infer<typeof MyAppsResponseSchema>;

export type AppResponse = z.infer<typeof AppResponseSchema>;

/* --------------------------- UPDATE APP --------------------------- */

export interface UpdateAppRequest {
  id: string | number;
  name: string;
  description: string;
  technical_details: string;
}

export interface UpdateAppImagesRequest {
  id: string | number;
  creator_id: string;
  images: File[];
}

export interface UpdateAppLogoRequest {
  id: string | number;
  creator_id: string;
  logo: File;
}

export interface UpdateAppResponse {}

/* --------------------------- RELEASE APP --------------------------- */

export interface ReleaseAppRequest {
  creator_id: string;
  id: string | number;
  version: string;
  release_notes: string;
  changelog: string;
  file: File | string;
}

export interface ReleaseAppResponse {}
