import { Button, FormField, Input, Typography, useFailDialog } from '@prepaire/ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from '@tanstack/react-router';
import { useAccount } from '@shared/features/auth/queries';
import { useUpdateAppLogo } from '../../queries';
import { AppLogoUpdateFormData, appLogoUpdateSchema } from './formSchema';

import styles from './AppLogoUpdateForm.module.scss';

interface AppLogoUpdateFormProps {
  appId: string | number;
  appName: string;
}

export function AppLogoUpdateForm({ appName, appId }: AppLogoUpdateFormProps) {
  const { t } = useTranslation('appUpdate');
  const { t: tC } = useTranslation('common');

  const user = useAccount();
  const { mutateAsync: updateApp, isPending } = useUpdateAppLogo();

  const navigate = useNavigate();

  const [failDialog, toggleFailDialog] = useFailDialog({
    okText: tC('ok'),
    content: t('errorText'),
  });

  const { handleSubmit, control } = useForm<AppLogoUpdateFormData>({
    resolver: zodResolver(appLogoUpdateSchema),
  });

  const onSubmit = async (formData: AppLogoUpdateFormData) => {
    if (!user) {
      return;
    }

    try {
      await updateApp({
        id: appId,
        logo: formData.logo[0],
        creator_id: user.id,
      });
    } catch (error) {
      toggleFailDialog();
      return;
    }

    navigate({ to: '/apps/$appId', params: { appId: appId.toString() } });
  };

  return (
    <main className={clsx(styles.appUpdate)}>
      {failDialog}
      <Typography variant="h1" className={styles.title}>
        {t('logoTitle', { name: appName })}
      </Typography>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <FormField className={styles.screenshotsField} name="logo" label={<Typography variant="h4">{t('logoLabel')}</Typography>}>
          <Controller
            name="logo"
            control={control}
            render={({ field }) => (
              <Input.Images
                classNames={{ previewList: styles.screenshotsPreviewList }}
                onChange={field.onChange}
                maxFiles={1}
                mode="square"
              />
            )}
          />
        </FormField>

        <div className={styles.formBottom}>
          <Button type="submit" variant="primary" disabled={isPending} loader={isPending}>
            {t('submitBtn')}
          </Button>
        </div>
      </form>
    </main>
  );
}
