import { z } from 'zod';

export const TEXT_FIELD_MAX = 1000;

export const appUploadSchema = z.object({
  name: z.string().min(1).max(TEXT_FIELD_MAX),
  description: z.string().min(1).max(TEXT_FIELD_MAX),
  technicalDetails: z.string().min(1).max(TEXT_FIELD_MAX),
  fileOrLink: z.union([z.instanceof(File), z.string().min(1)]),
  screenshots: z.array(z.instanceof(File)).min(1),
  logo: z.array(z.instanceof(File)).min(1).max(1).optional(),
});

export type AppUploadFormData = z.infer<typeof appUploadSchema>;
