export enum Subject {
  IndexPage = 'indexPage',
  App = 'app',
  AdminRoutes = 'adminRoutes',
  AppRequest = 'appRequest',
  AppReviewRequest = 'appReviewRequest',
}

export enum Action {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}
