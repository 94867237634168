import { createFileRoute } from '@tanstack/react-router';
import { ReleaseAppPage } from '@pages/app-management/ReleaseAppPage';
import { ensureApp } from '@features/app-management/queries';

export const Route = createFileRoute('/_auth/_main/apps/$appId/release')({
  loader: ({ params }) => ensureApp(params.appId),
  pendingComponent: () => <div>PENDING</div>,
  errorComponent: () => <div>ERROR</div>,
  component: function ReleaseComponent() {
    const { appId } = Route.useParams();

    return <ReleaseAppPage appId={appId} />;
  },
});
