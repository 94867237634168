const appUpload = {
  title: 'App Upload',

  nameLabel: 'App name',
  namePh: 'Enter app name',
  nameHint: 'Coming soon!',

  descLabel: 'Application information',
  descPh: 'Enter a detailed description of application, focusing on its functions and key features',
  descHint: 'Coming soon!',

  repoLabel: 'Application file or link',
  repoPh: 'Upload the application file or provide a link to its location on the application server',
  repoHint: 'Coming soon!',

  techDetLabel: 'Technical description of functionality',
  techDetPh:
    'Attach a document that details the technical aspects of the application, including its functionalities, system requirements, and any dependencies',
  techDetHint: 'Coming soon!',

  screenshotsLabel: 'Screenshorts',

  logoLabel: 'Logo',

  submitBtn: 'Submit',

  specsLink: 'Specifications for size and format',
  moderationDisclaimer: 'The app will be moderated before uploading',

  progressMsg: 'Uploading application...',

  successText: 'All data has been successfully uploaded. <Link>View my apps</Link>',
  errorText: 'Could not upload data due to error',
} as const;

export default appUpload;
