const common = {
  signInTitle: '',

  emailLabel: 'البريد الإلكتروني',
  emailPh: 'youremail@example.com',

  passLabel: 'كلمة المرور',
  passPh: 'كلمة مرورك',

  signInBtn: 'تسجيل الدخول',
} as const;

export default common;
