import { createFileRoute } from '@tanstack/react-router';
import { AppRequestListPage } from '@pages/app-requests/AppRequestsPage';
import { ensureAppRequests } from '@features/app-request-management/queries';

export const Route = createFileRoute('/_auth/_main/admin/_admin/app-requests/')({
  loader: ensureAppRequests,
  component: AppRequestListPage,
  pendingComponent: () => <div>PENDING</div>,
  errorComponent: () => <div>ERROR</div>,
});
