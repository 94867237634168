import { useTranslation } from 'react-i18next';
import { Typography } from '@prepaire/ui-kit';
import { AppSummary } from '@features/app-management/components/AppSummary';
import { useSuspenseApps } from '@features/app-management/queries';
import { getName } from '@shared/utils/getName';
import styles from './MyAppsPage.module.scss';

export function MyAppsPage() {
  const { data: apps } = useSuspenseApps();

  const { t } = useTranslation('appManagement');

  if (!apps) {
    return null;
  }

  return (
    <main>
      <Typography variant="h1" className={styles.title}>
        {t('myAppsTitle')}
      </Typography>
      <section className={styles.appList}>
        {apps.map((app) => (
          <AppSummary
            key={app.id}
            name={app.name}
            description={app.description}
            logo={app.logo}
            id={app.id}
            status={app.status}
            userName={getName(app.creator)}
            runCount={app.runCount}
          />
        ))}
      </section>
    </main>
  );
}
