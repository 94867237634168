import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from './locales/en';
import { ar } from './locales/ar';

export const defaultNS = 'common';
export const resources = {
  en,
  ar,
} as const;

i18n.use(initReactI18next).init({
  debug: import.meta.env.DEV,
  lng: 'en',
  ns: ['common', 'validation', 'appUpload', 'auth'],
  defaultNS,
  resources,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export { i18n };
