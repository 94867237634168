import { Button, FormField, Input, ProgressBar, Typography, useSuccessDialog, useFailDialog } from '@prepaire/ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from '@tanstack/react-router';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { wait } from '@shared/utils/wait';
import { useProgress } from '@shared/utils/hooks/useProgress';
import { useAccount } from '@shared/features/auth/queries';
import { useUploadApp } from '../../queries';
import { AppUploadFormData, appUploadSchema, TEXT_FIELD_MAX } from './formSchema';

import styles from './AppUploadForm.module.scss';

export function AppUploadForm() {
  const { t } = useTranslation('appUpload');
  const { t: tC } = useTranslation('common');
  const user = useAccount();

  const [successDialog, toggleSuccess] = useSuccessDialog({
    okText: tC('ok'),
    content: <Trans t={t} i18nKey="successText" components={{ Link: <Link to="/apps" /> }} />,
  });
  const [failDialog, toggleFail] = useFailDialog({
    okText: tC('ok'),
    content: t('errorText'),
  });

  const { startProgress, endProgress, progress, resetProgress } = useProgress(200, 3);

  const { mutateAsync: uploadApp } = useUploadApp();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AppUploadFormData>({
    resolver: zodResolver(appUploadSchema),
  });

  const onSubmit = async (data: AppUploadFormData) => {
    if (!user) {
      return;
    }

    startProgress();

    try {
      await uploadApp({
        name: data.name,
        creator_id: user.id,
        description: data.description,
        technical_details: data.technicalDetails,
        images: data.screenshots,
        release: data.fileOrLink,
        logo: data.logo?.[0],
      });
    } catch (error) {
      endProgress();
      await wait(500);
      resetProgress();
      toggleFail(true);
      return;
    }
    endProgress();
    await wait(500);
    resetProgress();
    toggleSuccess(true);
  };

  return (
    <main className={clsx(styles.appUpload)}>
      {successDialog}
      {failDialog}
      <ProgressBar isShown={progress !== 0} progress={progress} fullScreen>
        <Typography variant="h2">{t('progressMsg')}</Typography>
      </ProgressBar>
      <Typography variant="h1" className={styles.title}>
        {t('title')}
      </Typography>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="name"
          label={<Typography variant="h4">{t('nameLabel')}</Typography>}
          hint={t('nameHint')}
          errors={errors.name}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => <Input {...field} placeholder={t('namePh')} maxLength={TEXT_FIELD_MAX} />}
          />
        </FormField>

        <FormField
          name="description"
          label={<Typography variant="h4">{t('descLabel')}</Typography>}
          hint={t('descHint')}
          errors={errors.description}
        >
          <Controller
            name="description"
            control={control}
            render={({ field }) => <Input.Textarea {...field} maxLength={TEXT_FIELD_MAX} placeholder={t('descPh')} />}
          />
        </FormField>

        <FormField name="fileOrLink" label={<Typography variant="h4">{t('repoLabel')}</Typography>} hint={t('repoHint')}>
          <Controller
            name="fileOrLink"
            control={control}
            render={({ field }) => <Input.FileOrLink onChange={field.onChange} description={t('repoPh')} />}
          />
        </FormField>

        <FormField
          name="technicalDetails"
          label={<Typography variant="h4">{t('techDetLabel')}</Typography>}
          hint={t('techDetHint')}
          errors={errors.technicalDetails}
        >
          <Controller
            name="technicalDetails"
            control={control}
            render={({ field }) => <Input.Textarea {...field} maxLength={TEXT_FIELD_MAX} placeholder={t('techDetPh')} />}
          />
        </FormField>

        <FormField
          className={styles.screenshotsField}
          name="screenshots"
          label={<Typography variant="h4">{t('screenshotsLabel')}</Typography>}
        >
          <Controller
            name="screenshots"
            control={control}
            render={({ field }) => (
              <Input.Images classNames={{ previewList: styles.screenshotsPreviewList }} onChange={field.onChange} maxFiles={10} />
            )}
          />
        </FormField>

        <FormField className={styles.screenshotsField} name="logo" label={<Typography variant="h4">{t('logoLabel')}</Typography>}>
          <Controller
            name="logo"
            control={control}
            render={({ field }) => (
              <Input.Images
                classNames={{ previewList: styles.screenshotsPreviewList }}
                onChange={field.onChange}
                maxFiles={1}
                mode="square"
              />
            )}
          />
        </FormField>

        <div className={styles.formBottom}>
          <div>
            <Typography className={styles.specs}>{t('specsLink')}</Typography>
            <br />
            <Typography variant="footnote">{t('moderationDisclaimer')}</Typography>
          </div>
          <Button type="submit" variant="primary">
            {t('submitBtn')}
          </Button>
        </div>
      </form>
    </main>
  );
}
